// https://www.color-meanings.com/

// Based on https://coolors.co/283d3b-197278-edddd4-c44536-772e25
// ... with manually made backgroundDark & primaryLight
// Also see, https://www.color-meanings.com/
module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#c6f1f5',

  primary: '#197278',
  primaryLight: '#209199',
  primaryDark: '#283D3B',

  secondary: '#c44536',
  secondaryLight: '#edddd4',
  secondaryDark: '#772e25',
};

/* --- Just some foolin' around--- */
// module.exports = {
//   background: '#FFFFFF',
//   backgroundDark: '#d9ddd9',

//   primary: '#319b2c',
//   primaryLight: '#6dd468',
//   primaryDark: '#195016',

//   secondary: '#303030',
//   secondaryLight: '#858585',
//   secondaryDark: '#000000',
// };

// IDEA---
/*
 * Develop theme per Role (Cool Guy, etc)
 * Have the Role "wheel" pause whenever it is hovered over. Apply the neat link style from About Me to it?
 * When clicked have site togge between default theme and Role theme...
 * "Engineer": Green and black hacker theme?
 * "Spartan": Red and black theme?
 * "Cool Guy": Gold and green?
 */
